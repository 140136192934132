import React from "react";

interface IICSwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  containerClassName?: string;
}
const ICSwitch: React.FC<IICSwitchProps> = ({
  containerClassName,
  placeholder,
  required = false,
  disabled = false,
  checked,
  onChange,
}) => {
  const handleOnChange = () => {
    if (checked) {
      onChange(false);
    } else {
      onChange(true);
    }
  };
  return (
    <div
      onClick={() => (!disabled ? handleOnChange() : {})}
      className={`flex items-center cursor-pointer ${
        containerClassName ? containerClassName : ""
      }`}
    >
      {placeholder && <div>{placeholder + (required ? "*" : "")}</div>}
      <div
        className={`relative inline-flex items-center h-[20px] w-9 rounded-full duration-300 focus:outline-none overflow-hidden ml-3 ${
          checked ? "bg-skin-switch-active" : "bg-skin-switch-inactive"
        }`}
      >
        <span
          className={`absolute grid place-items-center h-3.5 w-3.5 transform rounded-full bg-white transition-transform duration-300 ease-in-out ${
            checked ? "translate-x-5" : "translate-x-[2.5px] shadow-inner"
          }`}
        ></span>
      </div>
    </div>
  );
};

export default ICSwitch;
