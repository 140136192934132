import React, { useEffect, useState } from "react";
import ICFormikSwitch from "../../../../../components/formik-input/ICFormikSwitch";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ICFileUpload from "../../../../../core-component/ICFileUpload";
import {
  basicInfoServiceValidationSchema,
  getBasicInfoServiceById,
  getBasicInfoInitialValues,
  handleSubmitBasicInfoService,
  fetchServiceSubCategoryDdl,
} from "./BasicInfoController";

import { ErrorMessage, FieldArray, Form, Formik, FormikProvider } from "formik";
import ICFormikTextInput from "../../../../../components/formik-input/ICFormikTextInput";
import ICFormikDropDown from "../../../../../components/formik-input/ICFormikDropDown";
import ICFormikRadioBtn from "../../../../../components/formik-input/ICFormikRadioBtn";
import ICFormikDatePicker from "../../../../../components/formik-input/ICFormikDatePicker";
import {
  BIT_VALUE,
  DATE_FORMAT,
  IMAGE_TYPE,
} from "../../../../../utils/AppEnumerations";
import ICButton from "../../../../../core-component/ICButton";
import { fetchServiceCategoryDdl } from "../../../product-service-category/ProductServiceCategoryController";
import { deleteIcon } from "../../../../../assets/icons/SvgIconList";
import { useLocation, useNavigate } from "react-router-dom";
import ICFormikWeekdaysSelect from "../../../../../core-component/ICFormikWeekDaysSelect";
import CustomEditor from "../../../../../core-component/CustomEditor";
import { fetchUploadImageService } from "../../../../global/image-service/ImageServices";
import {
  IAddService,
  IDDL,
  IMediaItemImage,
} from "../../../../../data/AppInterface";
import { IServiceSubCategory } from "../../../product-service-subcategory/ProductServiceSubCategoryController";
import { TReactSetState } from "../../../../../data/AppType";
import ICTextInput from "../../../../../core-component/ICTextInput";
import UseTranslationHook from "../../../../../hooks/UseTranslationHook";
import {
  SweetAlertError,
  getLocalDate,
} from "../../../../../utils/AppFunctions";
import {
  DAYS,
  GENDER_OPTIONS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../utils/AppConstants";
import ICSwitch from "../../../../../core-component/ICSwitch";

interface IServiceProps {
  setCurrentIndex: TReactSetState<number>;
}

const BasicInfo: React.FC<IServiceProps> = ({ setCurrentIndex }) => {
  const { t } = UseTranslationHook();
  let timer: NodeJS.Timeout;
  const [buttonController, setButtonController] = useState(true);
  const [categoryId, setCategoryId] = useState<number>();
  const [subCategoryId, setSubCategoryId] = useState<number>();
  const [serviceCategoryList, setServiceCategoryList] = useState<IDDL[]>([]);
  const [serviceSubCategory, setServiceSubCategory] = useState<IDDL[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [basicInfoInitialValues, setBasicInfoInitialValues] =
    useState<IAddService>();
  const [basicInfoImage, setBasicInfoImage] = useState<IMediaItemImage[]>([]);
  const [selectionVisibility, setSelectionVisibility] = useState<string>(
    BIT_VALUE.Zero
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const serviceId: number = location?.state?.productServiceId;
  const readOnly: boolean = location?.state?.readOnly;
  const copyService = location?.state?.copyService;
  const [freeAttempt, setFreeAttempt] = useState(false);

  const handleSwitchChange = (value: boolean) => {
    setFreeAttempt(value);
  };

  useEffect(() => {
    if (serviceId) {
      fetchServiceById();
    } else {
      setBasicInfoInitialValues(getBasicInfoInitialValues);
      fetchServiceCategory();
    }
  }, []);

  const fetchServiceById = async () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      const basicInfoUpdate = await getBasicInfoServiceById(
        serviceId,
        copyService,
        navigate
      );
      setBasicInfoInitialValues(basicInfoUpdate);
      setSelectionVisibility(basicInfoUpdate?.is_published);
      setCategoryId(basicInfoUpdate?.service_category_id);
      setSubCategoryId(basicInfoUpdate?.service_sub_category_id);
      await fetchServiceCategory(basicInfoUpdate?.service_category_id);
    }, 500);
  };
  const fetchServiceCategory = async (categoryId?: number) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      await fetchServiceCategoryDdl(setServiceCategoryList, categoryId);
    }, 500);
  };

  const categoryOptions = serviceCategoryList.map(
    ({ data_value, display_value }) => ({
      value: data_value,
      label: display_value,
    })
  );

  const handleDropDownChange = (selectedValue: number) => {
    setCategoryId(selectedValue);
  };

  useEffect(() => {
    if (categoryId) {
      fetchServiceSubCategoryDdl(
        setServiceSubCategory,
        categoryId,
        categoryId === basicInfoInitialValues?.service_category_id
          ? subCategoryId
          : undefined
      );
    }
  }, [categoryId]);

  const subCategoryOptions = serviceSubCategory.map(
    ({ data_value, display_value }) => ({
      value: data_value,
      label: display_value,
    })
  );

  useEffect(() => {
    if (
      basicInfoInitialValues?.service_media &&
      basicInfoInitialValues.service_media.length > 0
    ) {
      const transformedData = basicInfoInitialValues.service_media.map(
        (item) => ({
          ...item,
          publish_date: getLocalDate(),
        })
      );
      setBasicInfoImage(transformedData);
    } else {
      setBasicInfoImage([]);
    }
  }, [basicInfoInitialValues]);

  const handleSubmitBasicServiceWithFileUpload = async (
    values: IAddService
  ) => {
    try {
      setLoading(true);
      const updatedServiceMedia = basicInfoImage ? basicInfoImage : [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append(`myImageFile`, selectedFiles[i]);
        formData.append(`type`, IMAGE_TYPE.Services);
        values.id && formData.append(`typeId`, values.id.toString());
        const mediaUrl = await fetchUploadImageService(formData);
        if (!mediaUrl) {
          return null;
        }
        const newMediaItem = {
          media_type: "i",
          media_url: mediaUrl,
          media_title: selectedFiles[i].name,
        };
        updatedServiceMedia.push(newMediaItem);
      }

      await handleSubmitBasicInfoService(
        {
          ...values,
          service_media: updatedServiceMedia,
          is_published: selectionVisibility,
          feedback_tags: values.feedback_tags?.filter((item) => item !== ""),
          service_tags: values.service_tags?.filter((item) => item !== ""),
        },
        navigate,
        setCurrentIndex,
        buttonController,
        t
      );
    } catch (e) {
      SweetAlertError(MESSAGE_UNKNOWN_ERROR_OCCURRED);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (basicInfoInitialValues && basicInfoInitialValues.free_attempt) {
      setFreeAttempt(basicInfoInitialValues.free_attempt >= 1);
    }
  }, [basicInfoInitialValues]);

  return (
    <div className="p-4">
      {basicInfoInitialValues && (
        <Formik
          initialValues={basicInfoInitialValues}
          validationSchema={basicInfoServiceValidationSchema(
            t,
            selectionVisibility || BIT_VALUE.Zero,
            freeAttempt
          )}
          enableReinitialize
          onSubmit={(values) => handleSubmitBasicServiceWithFileUpload(values)}
        >
          {(formikPorps) => {
            const { handleSubmit, values, setFieldValue } = formikPorps;

            return (
              <>
                <Form onSubmit={handleSubmit}>
                  {!readOnly &&
                    basicInfoInitialValues.has_incomplete_session ===
                      BIT_VALUE.One && (
                      <div className="text-yellow-500 mb-4">
                        {t("service_edit_disable")}
                      </div>
                    )}
                  <div className=" grid grid-cols-1 xl:grid-cols-12 gap-2 ">
                    <div className="col-span-7 main_border">
                      <div className="pt-3">
                        <ICFormikTextInput
                          {...formikPorps}
                          fieldName={"service_title"}
                          placeholder={t("service_title")}
                          disabled={readOnly}
                          autoFocus
                          required
                        />
                        <ICFormikDropDown
                          {...formikPorps}
                          name={"service_category_id"}
                          placeholder={t("service_category_id")}
                          options={categoryOptions}
                          onChange={handleDropDownChange}
                          searchable
                          disabled={
                            readOnly ||
                            basicInfoInitialValues?.has_incomplete_session ===
                              BIT_VALUE.One
                          }
                          required
                        />
                        <ICFormikDropDown
                          {...formikPorps}
                          name={"service_sub_category_id"}
                          placeholder={t("service_sub_category_id")}
                          options={subCategoryOptions}
                          searchable
                          disabled={
                            readOnly ||
                            basicInfoInitialValues?.has_incomplete_session ===
                              BIT_VALUE.One
                          }
                          required
                        />
                      </div>
                      <div className="flex justify-between">
                        <ICFormikRadioBtn
                          {...formikPorps}
                          name={"service_for_gender"}
                          placeholder={t("service_for_gender")}
                          radioGroup={GENDER_OPTIONS}
                          disabled={
                            readOnly ||
                            basicInfoInitialValues?.has_incomplete_session ===
                              BIT_VALUE.One
                          }
                          required
                        />
                        {values && (
                          <ICFormikSwitch
                            {...formikPorps}
                            containerClassName={" grid place-items-start"}
                            name={"is_offline"}
                            placeholder={t("service_offline_service")}
                            checkedValue={BIT_VALUE.One}
                            unCheckedValue={BIT_VALUE.Zero}
                            disabled={
                              readOnly ||
                              basicInfoInitialValues?.has_incomplete_session ===
                                BIT_VALUE.One
                            }
                          />
                        )}
                        <ICFormikTextInput
                          {...formikPorps}
                          fieldName={"service_age_min_criteria"}
                          placeholder={t("service_age_min_criteria")}
                          type="number"
                          disabled={
                            readOnly ||
                            basicInfoInitialValues?.has_incomplete_session ===
                              BIT_VALUE.One
                          }
                        />
                        <ICFormikTextInput
                          {...formikPorps}
                          fieldName={"service_age_max_criteria"}
                          placeholder={t("service_age_max_criteria")}
                          type="number"
                          disabled={
                            readOnly ||
                            basicInfoInitialValues?.has_incomplete_session ===
                              BIT_VALUE.One
                          }
                        />
                      </div>
                      <div>
                        <div className="border p-2 mb-4 space-y-2 rounded-lg">
                          <h1>
                            {t("service_desc")}
                            {"*"}
                          </h1>
                          <CustomEditor
                            formikPorps={formikPorps}
                            fieldName="service_desc"
                            text={`${values.service_desc}`}
                          />
                          <ErrorMessage
                            name={"service_desc"}
                            component="div"
                            className="typo-error"
                          />
                        </div>
                        <FormikProvider value={formikPorps}>
                          <div className="grid grid-cols-2 space-x-4 w-full ">
                            <div className="border p-2 rounded-md overflow-y-scroll">
                              <h1>{t("service_tags")}</h1>
                              <div className="flex gap-4">
                                <FieldArray
                                  name="service_tags"
                                  render={(arrayHelpers) => (
                                    <div className="w-full">
                                      {formikPorps?.values?.service_tags?.map(
                                        (item, index) => (
                                          <div
                                            className="mb-4 mt-2"
                                            key={index}
                                          >
                                            <div className="flex gap-4 ">
                                              <div className="w-full">
                                                <ICTextInput
                                                  label={t("service_tags")}
                                                  name={`service_tags[${index}]`}
                                                  value={
                                                    formikPorps?.values
                                                      ?.service_tags?.[index]
                                                  }
                                                  onChange={
                                                    formikPorps.handleChange
                                                  }
                                                  disabled={readOnly}
                                                  containerClassName="services-input mt-2"
                                                />
                                              </div>
                                              {!readOnly && (
                                                <button
                                                  disabled={
                                                    formikPorps.values
                                                      .service_tags?.length ===
                                                    0
                                                  }
                                                  type="button"
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                  className="deleteButton action-view"
                                                >
                                                  {deleteIcon}
                                                </button>
                                              )}
                                            </div>
                                            <ErrorMessage
                                              name={`service_tags[${index}]`}
                                              component="div"
                                              className="typo-error"
                                            />
                                          </div>
                                        )
                                      )}
                                      {!readOnly && (
                                        <ICButton
                                          className="custom-button typo-btn-primary bg-skin-primary  w-full"
                                          type="button"
                                          onClick={() => arrayHelpers.push("")}
                                        >
                                          {t("add_service_tags")}
                                        </ICButton>
                                      )}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="border p-2 rounded-md overflow-y-scroll">
                              <h1>{t("service_feedback_tags")}</h1>
                              <div className="flex gap-4">
                                <FieldArray
                                  name="feedback_tags"
                                  render={(arrayHelpers) => (
                                    <div className="w-full">
                                      {formikPorps?.values?.feedback_tags?.map(
                                        (item, index) => (
                                          <div
                                            className="mb-4 mt-2"
                                            key={index}
                                          >
                                            <div className="flex gap-4">
                                              <div className="w-full">
                                                <ICTextInput
                                                  label={t(
                                                    "service_feedback_tags"
                                                  )}
                                                  name={`feedback_tags[${index}]`}
                                                  value={
                                                    formikPorps?.values
                                                      ?.feedback_tags?.[index]
                                                  }
                                                  onChange={
                                                    formikPorps.handleChange
                                                  }
                                                  disabled={readOnly}
                                                  containerClassName="services-input mt-2"
                                                />
                                              </div>
                                              {!readOnly && (
                                                <button
                                                  disabled={
                                                    formikPorps.values
                                                      .feedback_tags?.length ===
                                                    0
                                                  }
                                                  type="button"
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                  className="deleteButton action-view"
                                                >
                                                  {deleteIcon}
                                                </button>
                                              )}
                                            </div>
                                            <ErrorMessage
                                              name={`feedback_tags[${index}]`}
                                              component="div"
                                              className="typo-error"
                                            />
                                          </div>
                                        )
                                      )}
                                      {!readOnly && (
                                        <ICButton
                                          className="custom-button typo-btn-primary bg-skin-primary mb-6 w-full"
                                          type="button"
                                          onClick={() => arrayHelpers.push("")}
                                        >
                                          {t("add_feedback_tags")}
                                        </ICButton>
                                      )}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </FormikProvider>
                      </div>
                    </div>
                    <div className="col-span-5 main_border">
                      <div className="w-full pt-3">
                        <div className="flex justify-between">
                          <div>
                            <ICFormikSwitch
                              {...formikPorps}
                              containerClassName={
                                "flex items-center justify-start gap-5"
                              }
                              name={"is_gender_specific"}
                              placeholder={t("is_gender_specific")}
                              checkedValue={BIT_VALUE.One}
                              unCheckedValue={BIT_VALUE.Zero}
                              disabled={
                                readOnly ||
                                basicInfoInitialValues?.has_incomplete_session ===
                                  BIT_VALUE.One
                              }
                            />
                          </div>
                          <div>
                            <ICFormikSwitch
                              {...formikPorps}
                              containerClassName={
                                "flex items-center justify-start gap-5"
                              }
                              name={"is_peer_to_peer"}
                              placeholder={t("is_peer_to_peer")}
                              checkedValue={BIT_VALUE.One}
                              unCheckedValue={BIT_VALUE.Zero}
                              disabled={
                                readOnly ||
                                basicInfoInitialValues?.has_incomplete_session ===
                                  BIT_VALUE.One
                              }
                            />
                          </div>
                        </div>
                        {values && (
                          <ICFormikSwitch
                            {...formikPorps}
                            containerClassName={
                              "flex items-center justify-start gap-5"
                            }
                            name={"is_active"}
                            placeholder={t("service_status")}
                            checkedValue={BIT_VALUE.One}
                            unCheckedValue={BIT_VALUE.Zero}
                            disabled={readOnly}
                          />
                        )}
                        <div className="flex mb-6 gap-2">
                          <h1 className="font-semibold">
                            {t("service_visibility")}
                          </h1>
                          <div className="flex items-center space-x-2 ">
                            <input
                              type="radio"
                              id="0"
                              name="draft"
                              value={BIT_VALUE.Zero}
                              checked={selectionVisibility === BIT_VALUE.Zero}
                              onChange={() =>
                                setSelectionVisibility(BIT_VALUE.Zero)
                              }
                              className="form-radio h-4 w-4 "
                              readOnly={
                                readOnly ||
                                basicInfoInitialValues?.has_incomplete_session ===
                                  BIT_VALUE.One
                              }
                            />
                            <label htmlFor="draft">{t("draft")}</label>
                          </div>
                          <div className="flex items-center space-x-2 mx-3">
                            <input
                              type="radio"
                              id="1"
                              name="published"
                              value={BIT_VALUE.One}
                              checked={selectionVisibility === BIT_VALUE.One}
                              onChange={() => {
                                setSelectionVisibility(BIT_VALUE.One);
                                setFieldValue("publish_date", getLocalDate());
                              }}
                              className="form-radio h-4 w-4"
                              readOnly={
                                readOnly ||
                                basicInfoInitialValues?.has_incomplete_session ===
                                  BIT_VALUE.One
                              }
                            />
                            <label htmlFor="published">{t("published")}</label>
                          </div>
                        </div>
                        <ICFormikDatePicker
                          {...formikPorps}
                          fieldName={"publish_date"}
                          placeholder={t("service_publish_date")}
                          dateProps={{
                            displayFormat: DATE_FORMAT["DD-Mon-YYYY"],
                            min: values.id
                              ? values.publish_date
                              : getLocalDate(),
                          }}
                          disabled={
                            readOnly ||
                            selectionVisibility === BIT_VALUE.Zero ||
                            basicInfoInitialValues?.has_incomplete_session ===
                              BIT_VALUE.One
                          }
                        />
                      </div>
                      <div className="mb-6">
                        <div className="flex justify-between">
                          <div>
                            <ICSwitch
                              checked={freeAttempt}
                              onChange={() => {
                                const newValue = !freeAttempt;
                                handleSwitchChange(newValue);

                                if (newValue) {
                                  setFieldValue("service_cost", 0);
                                } else {
                                  setFieldValue("free_attempt", null);
                                }
                              }}
                              placeholder={t("free_service")}
                              disabled={
                                readOnly ||
                                basicInfoInitialValues?.has_incomplete_session ===
                                  BIT_VALUE.One
                              }
                            />
                          </div>
                          {freeAttempt && (
                            <div className="w-2/5">
                              <ICFormikTextInput
                                {...formikPorps}
                                type="number"
                                fieldName={"free_attempt"}
                                placeholder={t("free_attempt")}
                                disabled={
                                  readOnly ||
                                  basicInfoInitialValues?.has_incomplete_session ===
                                    BIT_VALUE.One
                                }
                                autoFocus
                                required
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="w-2/5">
                          <ICFormikTextInput
                            {...formikPorps}
                            type="number"
                            fieldName={"service_cost"}
                            placeholder={t("service_cost")}
                            disabled={
                              readOnly ||
                              basicInfoInitialValues?.has_incomplete_session ===
                                BIT_VALUE.One ||
                              freeAttempt
                            }
                            required
                          />
                        </div>
                        <div className="w-2/5">
                          <ICFormikTextInput
                            {...formikPorps}
                            type="number"
                            fieldName={"service_discounted_price"}
                            placeholder={t("service_discounted_price")}
                            disabled={
                              readOnly ||
                              basicInfoInitialValues?.has_incomplete_session ===
                                BIT_VALUE.One
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-between">
                          <div className="w-2/5">
                            <ICFormikTextInput
                              {...formikPorps}
                              type="number"
                              fieldName={"session_duration"}
                              placeholder={t("session_duration")}
                              disabled={
                                readOnly ||
                                basicInfoInitialValues?.has_incomplete_session ===
                                  BIT_VALUE.One
                              }
                              required
                            />
                          </div>
                          <div className="w-2/5">
                            <ICFormikTextInput
                              {...formikPorps}
                              type="number"
                              fieldName={"service_duration"}
                              placeholder={t("service_duration")}
                              disabled={
                                readOnly ||
                                basicInfoInitialValues?.has_incomplete_session ===
                                  BIT_VALUE.One
                              }
                              required
                            />
                          </div>
                        </div>

                        <div className="w-3/4">
                          <ICFormikTextInput
                            {...formikPorps}
                            fieldName={"cancellation_cutoff_time_hours"}
                            placeholder={t("cancellation_cutoff_time_hours")}
                            type="number"
                            disabled={readOnly}
                          />
                        </div>

                        <div>
                          <ICFormikWeekdaysSelect
                            name={"service_days"}
                            label={t("service_days")}
                            weekdays={DAYS}
                            readOnly={
                              readOnly ||
                              basicInfoInitialValues?.has_incomplete_session ===
                                BIT_VALUE.One
                            }
                          />
                        </div>
                      </div>

                      <div className="w-full">
                        <div>
                          <div className="mt-5">
                            <ICFileUpload
                              title={t("poster_image")}
                              maxFiles={5}
                              maxFileSizeMB={5000}
                              acceptedFileTypes={["jpg", "png", "jpeg"]}
                              onFilesSelected={setSelectedFiles}
                              selectedFiles={selectedFiles}
                              setSelectedFiles={setSelectedFiles}
                              data={basicInfoImage}
                              setData={setBasicInfoImage}
                              disabled={readOnly}
                            />
                          </div>

                          <div className=" grid grid-cols-2 space-x-4 mt-5 w-full">
                            {!readOnly && (
                              <>
                                <div onClick={() => setButtonController(false)}>
                                  <ICButton
                                    disabled={loading}
                                    loading={
                                      buttonController === false
                                        ? loading
                                        : false
                                    }
                                    className={"w-full  "}
                                  >
                                    {t("save_button")}
                                  </ICButton>
                                </div>

                                <div onClick={() => setButtonController(true)}>
                                  <ICButton
                                    disabled={loading}
                                    loading={
                                      buttonController === true
                                        ? loading
                                        : false
                                    }
                                    className={"w-full"}
                                  >
                                    {t("save_and_next_button")}
                                  </ICButton>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default BasicInfo;
